@font-face {
  font-family: 'Courier';
  src: url('./assets/fonts/courier.ttf');
}

@font-face {
  font-family: 'Exo2Bold';
  src: url('./assets/fonts/Exo2-Bold.ttf');
}

@font-face {
  font-family: 'Exo2Medium';
  src: url('./assets/fonts/Exo2-Medium.ttf');
}

@font-face {
  font-family: 'Exo2Regular';
  src: url('./assets/fonts/Exo2-Regular.ttf');
}

.homePage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.logoMenu {
  display: flex;
  justify-content: center;
}

/* gallery */
.imgContainer {
  position: relative;
}

.aboutImgMask {
  background-color: #E8E7E7;
  width: 300px;
  height: 500px;
  position: absolute;
  top: 100px;
}

.aboutImg {
  top: 0px;
  position: relative;
}

.borderLine {
  border: 2px solid #000;
  height: auto;
  width: 2px;
  margin-right: 30px;
}

.facilityBox {
  display: flex;
}

.areYouCreator {
  position: relative;
  margin-top: 100px;
}

.floatingContent {
  background-color: rgba(255, 255, 255, 0.8);
  width: 350px;
  height: 600px;
  position: absolute;
  top: 0px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  padding: 20px;
}

.lineBreak {
  width: 200px;
  height: 2px;
  background-color: #000;
  float: right;
  margin-top: 20px;
}

.lineBreakLong {
  width: 350px;
  height: 2px;
  background-color: #000;
  margin-top: 20px;
}

.diySessionContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.titleRelative {
  position: relative;
}

.studioFacilityContainer {
  position: relative;
}

.studioFacilityBorder {
  width: 300px;
  height: 700px;
  border: 4px solid #6F1C4F;
  position: absolute;
  right: 0px;
  top: 0px;
}

.studioFacilityGif {
  position: relative;
  top: 25px;
}

.studioFacilityTxt {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  text-align: center;
}

.studioFacilityTxtMobile {
  position: absolute;
  top: 40%;
  left: 50%;
  width: 100%;
  height: 100%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  text-align: center;
}

.diyInput {
  border: 0px !important;
  border-bottom: 1px solid #000 !important;
  border-radius: 0px !important;
  font-family: 'Exo2Regular';
}

.formFontFamilty {
  font-family: 'Exo2Bold';
}

.summaryContainer {
  position: relative;
}

.diyShadow {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
}

.workShopListItems {
  position: relative;
}

.workShopListItemsContent {
  position: absolute;
  top: 0px;
  background-color: rgba(0,0,0,0.5);
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  padding: 20px;
}

.spaceIndoorListItems {
  position: relative;
}

.spaceIndoorListItemsContent {
  position: absolute;
  top: 0;
}

.headerNavBar {
  background-color: #fff !important;
  border-bottom: 1px solid #ecf0f1;
  font-family: 'Exo2Bold';
  color: #000 !important;
}

.calerndarView {
  font-family: 'Exo2Bold' !important;
}

.timingContainer {
  border: 1px solid #000;
  padding: 12px;
  text-align: center;
  margin-bottom: 20px;
}

.btn:focus {
  outline: none !important;
  outline-offset: none !important;
}

.hoverContainer {
  width: 100%;
  height: 400px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0px;
}

.hoverTxt {
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  font-family: 'Exo2Bold';
  color: #fff;
  position: absolute;
}

.link {
  text-decoration: none;
  color: #000;
}

.link:hover {
  text-decoration: none;
  color: #000;
}

.content-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
}

.space-content-blur {
  /* Add the blur effect */
  filter: blur(8px);
  -webkit-filter: blur(8px);
  background-color: #fff;
}

.space-img-align {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 520px;
}

.frame-img {
  width: 100%;
  height: 100%;
  border: 3px solid #76747A;
  background: #2C292A;
  margin: auto;
  padding: 15px 15px;
}

.img-wrapper {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
}

.contact-us-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.contact-float-box {
  border-top: 3px solid #000;
  border-left: 3px solid #000;
  width: 65%;
  height: 80%;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.img-slider {
  width: 400px;
  height: 250px;
  object-fit: cover;
}

.react-calendar__month-view__days__day--weekend{
  color: #000 !important;
}

.react-calendar__tile--now {
  background: #6F1C4F !important;
  color: #fff !important;
}

.react-calendar { 
  width: 400px;
  max-width: 100%;
  background-color: #fff;
  color: #222;
 }
 .react-calendar__navigation button {
  color: #6F1C4F;
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
 }
 .react-calendar__navigation button:enabled:hover,
 .react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
 }
 .react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
 }